import React from "react"
import Layout from "../components/layouts"
import {
  HeroVideo,
  ExpertiseBanner,
  CaseStudyBanner,
  ContactBanner,
  SeoBlock,
} from "../components/slices"
import { graphql } from "gatsby"

export const query = graphql`
  query HomepageQuery {
    allContentfulHomepage {
      nodes {
        seo {
          title
          description
        }
        title
        hero {
          title
          subtext {
            raw
          }
        }
        listing {
          title
          subtext {
            raw
          }
          link {
            page {
              slug
              title
              mainImage {
                fluid(maxWidth: 420) {
                  ...GatsbyContentfulFluid
                }
              }
            }
            slug
          }
        }
        caseStudies {
          title
          subtext {
            raw
          }
          link {
            page {
              slug
              title
              mainImage {
                fluid(maxWidth: 420) {
                  ...GatsbyContentfulFluid
                }
              }
            }
            slug
          }
        }
        contactForm {
          title
          subtext {
            raw
          }
        }
      }
    }
  }
`

const Homepage = ({ data }) => {
  const { seo, hero, listing, caseStudies, contactForm } =
    data.allContentfulHomepage.nodes[0]
  return (
    <Layout>
      <SeoBlock data={seo} />
      <HeroVideo data={hero} />
      {listing && <ExpertiseBanner data={listing} />}
      {caseStudies && <CaseStudyBanner data={caseStudies} />}
      {contactForm && <ContactBanner data={contactForm} />}
    </Layout>
  )
}

export default Homepage
